import {ActionMenu, ActionList, IconButton, Link, Truncate} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {type Icon, KebabHorizontalIcon} from '@primer/octicons-react'
import type {IssueReference as IssueReferenceType} from '../../types/blackbird-types'
import {getIssueIconColor, getIssueIcon} from '../../utilities/issue-utils'

export default function IssueReferences({issueRefs}: {issueRefs: IssueReferenceType[]}) {
  if (!issueRefs || issueRefs.length === 0) return <></>
  const [pullRequests, issues] = splitIssuesAndPRs(issueRefs)

  return (
    <ActionMenu>
      <ActionMenu.Anchor>
        {/* eslint-disable-next-line primer-react/a11y-remove-disable-tooltip */}
        <IconButton
          unsafeDisableTooltip={true}
          icon={KebabHorizontalIcon}
          variant="invisible"
          aria-label="Open linked issues"
        />
      </ActionMenu.Anchor>

      <ActionMenu.Overlay sx={{minWidth: '350px'}}>
        <>
          {issues!.length > 0 && (
            <ActionList>
              <ActionList.Group>
                <ActionList.GroupHeading>Linked issues</ActionList.GroupHeading>
                {issues!.map((issue, i) => {
                  // eslint-disable-next-line @eslint-react/no-array-index-key
                  return <IssueReference issue={issue} key={i} />
                })}
              </ActionList.Group>
            </ActionList>
          )}
          {issues!.length > 0 && pullRequests!.length > 0 && <ActionList.Divider />}
          {pullRequests!.length > 0 && (
            <ActionList sx={{pt: 0}}>
              <ActionList.Group>
                <ActionList.GroupHeading>Linked pull requests</ActionList.GroupHeading>
                {pullRequests!.map((issue, i) => {
                  // eslint-disable-next-line @eslint-react/no-array-index-key
                  return <IssueReference issue={issue} key={i} />
                })}
              </ActionList.Group>
            </ActionList>
          )}
        </>
      </ActionMenu.Overlay>
    </ActionMenu>
  )
}

function IssueReference({issue}: {issue: IssueReferenceType}) {
  const iconColor = getIssueIconColor(issue.state, issue.is_pull_request, issue.merged, issue.reviewable_state)
  const icon: Icon = getIssueIcon(issue.state, issue.is_pull_request)

  return (
    <ActionList.Item>
      <ActionList.LeadingVisual>
        <Octicon
          icon={icon}
          size={16}
          sx={{
            mr: 2,
            color: iconColor,
          }}
        />
      </ActionList.LeadingVisual>
      <Truncate title={issue.title} sx={{maxWidth: '250px'}}>
        <Link href={issue.permalink} muted>
          {issue.title}
        </Link>
      </Truncate>
      <ActionList.TrailingVisual>#{issue.id}</ActionList.TrailingVisual>
    </ActionList.Item>
  )
}

function splitIssuesAndPRs(issueRefs: IssueReferenceType[]) {
  const pullRequests: IssueReferenceType[] = []
  const issues: IssueReferenceType[] = []
  for (const issue of issueRefs) {
    issue.is_pull_request ? pullRequests.push(issue) : issues.push(issue)
  }
  return [pullRequests, issues]
}

try{ IssueReferences.displayName ||= 'IssueReferences' } catch {}
try{ IssueReference.displayName ||= 'IssueReference' } catch {}