import type {IssueResultItem, PullRequestsResults} from '../../types/blackbird-types'
import Result from '../search-result'
import {IssueResult} from './Issues'

export default function PullRequests({results}: {results: PullRequestsResults}) {
  return (
    <Result.List>
      {results.results.map((item: IssueResultItem, i: number) => (
        // Pull requests and issues come back from the server with the same response type, so re-use the component.
        <IssueResult
          // eslint-disable-next-line @eslint-react/no-array-index-key
          key={i}
          item={item}
          isPullRequest={true}
        />
      ))}
    </Result.List>
  )
}

try{ PullRequests.displayName ||= 'PullRequests' } catch {}